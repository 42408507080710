import image1 from "../../assets/candies/Drops Halls Mini Cereja.webp"
import image2 from "../../assets/candies/trident max.webp"
import image3 from "../../assets/candies/trident x senses.webp"
import image4 from "../../assets/candies/trident garrafa tangerina.webp"
import image5 from "../../assets/candies/trident mini melancia.webp"
import image6 from "../../assets/candies/trident garrafa melancia.webp"
import image7 from "../../assets/candies/3 displays halls.webp"

const data = [
    {
        img: image1,
        title: 'Drops Halls Mini sem açúcar c/18'
    },
    {
        img: image2,
        title: "Chiclete Trident Max Blueberry Menta 14un"
    },
    {
        img: image3,
        title: "Kit Chiclete Trident X Senses 2 Displays"
    },
    {
        img: image4,
        title: "6 potes Trident Garrafa Xfresh Tangerina"
    },
    {
        img: image5,
        title: "Drops Halls Mini sem açúcar c/18"
    },
    {
        img: image6,
        title: "6 potes Trident Garrafa Xsenses melancia"
    },
    {
        img: image7,
        title: "Drops Halls Mini sem açúcar kit 3 Displays"
    },
]

export default data