import './candies.css'
import data from './candiesData'

import { Container } from 'react-bootstrap';
import { ProductCard } from '../Card/Card';

export function Candies() {
    return (
        <div className='confeitos' id='confeitos'>
            <Container className='space'>
                <h2 className='pt-5 confeitosUnderline'>
                    CONFEITOS
                </h2>
                <h2 >
                    Os confeitos que não podem faltar no seu dia-a-dia.
                </h2>
                <div className='candiesList'>
                    {data.map((product, index) =>
                        <ProductCard
                            key={index}
                            product={product}
                        />
                    )}
                </div>
            </Container>
        </div>
    );
}