import { FirstSection } from './components/FirstSection/FirstSection';
import { Electronics } from './components/Electronics/Electronics';
import { Footer } from './components/Footer/Footer';
import { Candies } from './components/Candies/Candies';
import { LinkDiv } from './components/LinkDiv/LinkDiv';

function App() {

  return (
    <>
      <FirstSection />
      <Electronics/>
      <LinkDiv/>
      <Candies/>
      <Footer/>
    </>
  );
}

export default App;
