/* eslint-disable jsx-a11y/alt-text */
import { BsWhatsapp, BsEnvelope } from "react-icons/bs";
import { BiMap } from "react-icons/bi";
import Container from 'react-bootstrap/Container';

import './footer.css'

export function Footer() {
    return (

        <footer class="footer-distributed" id='contact'>
            <Container className="style">
                <div class="footer-left">
                    <img id='logo' src={require("../../assets/confishop_logo/confishop_logo_transparent1.png")} />
                </div>

                <div class="footer-center">
                    <div>
                        <BiMap id="mapIcon" />&nbsp;
                        <span>Guarulhos - São Paulo - Brasil</span>
                    </div>
                    <div>
                        <BsWhatsapp id="whatsAppIcon" />&nbsp;
                        <a href="https://wa.me/c/5511991346947" target="_blank" rel="noreferrer">+55 11 99134-6947</a>
                    </div>
                    <div>
                        <BsEnvelope id="envelopeIcon" />&nbsp;
                        <a href="mailto:atendimento@confishop.com.br">atendimento@confishop.com.br</a>
                    </div>
                    <div class="footer-icons">
                        <a href="https://lista.mercadolivre.com.br/_CustId_7945691" target="_blank" rel="noreferrer"><img id='logoFooter' src={require("../../assets/icons/mercadoLivreLogo.png")} /></a>
                        <a href="https://instagram.com/confishopstore?igshid=NDk5N2NlZjQ=" target="_blank" rel="noreferrer"><img id='logoFooter' src={require("../../assets/icons/instagramLogo.png")} /></a>
                    </div>
                </div>

                <div class="footer-right">
                    <p class="footer-company-about">
                        <span>Confishop Comercial Ltda</span>
                        <span>CNPJ: 47.730.535/0001-81</span>
                    </p>

                </div>
            </Container>
        </footer>
    )
}