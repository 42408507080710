import image1 from "../../assets/electronics/amazon_Echo_Dot_3rd_Gen.webp"
import image2 from "../../assets/electronics/Amazon Echo Dot 4th Gen.webp"
import image3 from "../../assets/electronics/amazon Echo Show 5 2nd Gen.webp"
import image4 from "../../assets/electronics/Amazon Echo Dot 4th Gen White.webp"
import image5 from "../../assets/electronics/Amazon Echo Show 8.webp"
import image6 from "../../assets/electronics/Video Game Retro.webp"
import image7 from "../../assets/electronics/Alto-falante JBL Go 2.webp"

const data = [
    {
        img: image1,
        title: 'Amazon Echo Dot 3rd Gen'
    },
    {
        img: image2,
        title: 'Amazon Echo Dot 4th Gen Charcoal'
    },
    {
        img: image3,
        title: 'Amazon Echo Show 5 2nd Gen display integrado de 5.5" Charcoal'
    },
    {
        img: image4,
        title: 'Amazon Echo Dot 4th Gen com relógio display integrado'
    },
    {
        img: image5,
        title: 'Amazon Echo Show 8 display integrado de 8" Charcoal'
    },
    {
        img: image6,
        title: 'Video Game Retro 4K com 2 controles sem fio'
    },
    {
        img: image7,
        title: 'Alto-falante JBL Go 2'
    },
]

export default data