import './firstSection.css'
import { Topbar } from '../NavBar/Topbar';
import { Container } from 'react-bootstrap';

export function FirstSection() {
    return (
        <div id="firstSection">
            <Topbar />
            <Container>
                <h1 className='mainPhrase'>
                    Confiança e os melhores preços do mercado pensando em você!
                </h1>
            </Container>
        </div>
    );
}