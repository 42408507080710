import './linkDiv.css'

import { Container } from 'react-bootstrap';

export function LinkDiv() {
    return (
        <div style={{ position: "relative" }} id='catalog'>
            <div className='backgroundImg'>
            </div>
            <Container className='linkDiv'>
                <h3 >
                    Clique e acesse o catálogo completo
                </h3>
                <div className="linkDivIcons">
                    <a href="https://lista.mercadolivre.com.br/_CustId_7945691" target="_blank" className='backgroundIcon' rel="noreferrer"><img id='logoMercadoLivre' src={require("../../assets/icons/mercadoLivreLogo.png")} /></a>
                    <a href="https://wa.me/c/5511991346947" target="_blank" className='backgroundIcon' rel="noreferrer"><img id='logoWhatsApp' src={require("../../assets/icons/pngwing.com.png")} /></a>
                </div>
            </Container>
        </div>
    );
}