/* eslint-disable jsx-a11y/alt-text */
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './topbar.css'

import { AiOutlineMobile } from "react-icons/ai";
import { TbCandy } from "react-icons/tb";
import { BsTelephone, BsBook } from "react-icons/bs";

import { useState } from 'react';

export function Topbar() {

    const [isActive, setIsActive] = useState(false);

    function handleClick() {
        setIsActive(current => !current);
      };

    return (
        <>
            <Navbar collapseOnSelect expand="md" id='navBar' className={isActive ? 'teste' : ''}>
                <Container>
                    <Navbar.Brand href='#electronics'>
                        <img id='logo' src={require("../../assets/confishop_logo/confishop_logo_transparent1.png")} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleClick}/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto itens">
                            <Nav.Link href="#electronics" onClick={handleClick}>
                                <AiOutlineMobile className='me-1' />
                                Eletrônicos
                            </Nav.Link>
                            <Nav.Link href="#catalog" onClick={handleClick}>
                                <BsBook className='me-1' />
                                Catálogo
                            </Nav.Link>
                            <Nav.Link href="#confeitos" onClick={handleClick}>
                                <TbCandy className='me-1' />
                                Confeitos
                            </Nav.Link>
                            <Nav.Link href="#contact" onClick={handleClick}>
                                <BsTelephone className='me-1' />
                                Contato
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}