import './Electronics.css'
import data from './electronicsData'

import { Container } from 'react-bootstrap';
import { ProductCard } from '../Card/Card';

export function Electronics() {
    return (
        <div className='electronics' id='electronics'>
            <Container className='space'>
                <h2 className='pt-5 underline'>
                    ELETRÔNICOS
                </h2>
                <h2 >
                    Os eletrônicos queridinhos da Confishop.
                </h2>
                <div className='electronicList'>
                    {data.map((product, index) =>
                        <ProductCard
                            key={index}
                            product={product}
                        />
                    )}
                </div>
            </Container>
        </div>
    );
}