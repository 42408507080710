import "./card.css"

export function ProductCard({ product }) {
    return (
        <div className="cardStyle">
            <div id='divImg'>
                <img src={product.img} id='productImg' />
            </div>
            <div className="productTitleDiv animated">
                <h3 className="productTitle">{product.title}</h3>
            </div>
        </div>
    );
}